import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'

import Subline from './Subline'

const PostWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &:hover {
    h2, .excerpt, .subline {
      color: ${props => props.theme.colors.primaryLight};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    flex-direction: column;
  }
`

const Post = styled.article`
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`

const Thumbnail = styled.div`
  margin-right: 1.5rem;
  width: 750px;
  height: 250px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    width: 100%;
    margin: 0 1rem;
  }
`

const Title = styled.h2`
  position: relative;
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.grey.default};
  font-size: 2rem;
  line-height: 1.2;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
  }
`

const Excerpt = styled.p`
  grid-column: -1 / 1;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.grey.default};
  font-size: 0.8rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 0.7rem;
  }
`

const Article = ({ title, date, excerpt, slug, timeToRead, categories, image }) => {
  const firstChar = title.charAt(0)

  const thumbImg = {
    backgroundImage: 'url(' + image + ')',
  };

  return (
    <Link to={slug}>
      <PostWrap>
        <Thumbnail style={thumbImg} />
        <Post>
          <Title>
            {title}
          </Title>
          <Subline className="subline">
            {date} &mdash; {timeToRead} Min Read &mdash; In{' '}
            {categories.map((cat, i) => (
              <React.Fragment key={cat}>
                {!!i && ', '}
                {cat}
              </React.Fragment>
            ))}
          </Subline>
          <Excerpt className="excerpt">{excerpt}</Excerpt>
        </Post>
      </PostWrap>
    </Link>
  )
}

export default Article

Article.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
  categories: PropTypes.array.isRequired,
  image: PropTypes.string,
}
