import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { darken, lighten } from 'polished'
import '../assets/type/fonts.css'

const Wrapper = styled.header`
  grid-column: 1 / -1;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 1rem 1rem 4rem 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  box-shadow: inset 0px -10px 30px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .logo {
    padding: 0 1rem;
  }
  .nav {
    padding: 0 3rem;

    a {
      margin: 0 1rem;
      padding: 10px;
      font-size: 0.9rem;
      color: ${props => props.theme.colors.white};
      text-transform: uppercase;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    .logo {
      padding: 0;
      width: 60%;

      svg {
        max-width: 100%;
      }
    }
    .nav {
      padding: 0 0 0 1rem;
      width: 40%;
      text-align: right;

      a {
        padding: 0 0.25rem;
        font-size: 0.7rem;
      }
    }
  }

`

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;

  a {
    color: ${props => props.theme.colors.white};
    font-size: 1.2rem;
    &:hover {
      opacity: 0.85;
      color: ${props => props.theme.colors.white};
    }
    svg text {
      font-family: 'dehangsterregular', cursive;
    }
  }
  .nav > a {
    margin: 0 1rem;
    font-size: 0.75rem;
    text-transform: uppercase;
  }
`

const Header = ({ children, bkg }) => (
  <Wrapper>
    <Content>{children}</Content>
  </Wrapper>
)

export default Header

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}
