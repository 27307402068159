import styled from 'styled-components'

const Subline = styled.div`
  font-size: 0.8rem;
  color: ${props => props.theme.colors.grey.light};
  ${props => props.sectionTitle && 'margin-top: -3rem'};
  ${props => props.sectionTitle && 'margin-bottom: 4rem'};
  ${props => props.sectionTitle && 'text-align: center'};

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 0.7rem;
  }
`

export default Subline
