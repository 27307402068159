import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import Zoom from '@material-ui/core/Zoom';
import PsychologyToday from '../assets/img/psychology-today.svg'
import GirlfriendCircles from '../assets/img/girlfriend-circles.svg'
import WomansDay from '../assets/img/womansday.svg'
import MamaPedia from '../assets/img/mamapedia.svg'
import Cosmo from '../assets/img/cosmopolitan.svg'

const MediaRow = styled.div`
  position: relative;
  z-index: 1;
  padding: 1rem 3rem;
  background-color: #F5F5F5;
  border-top: 1px solid ${darken(0.1, '#F5F5F5')};
  border-bottom: 1px solid ${darken(0.1, '#F5F5F5')};

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 1rem;

    .media-container {
      div {
        margin: 0 0.5rem;
      }
    }
  }

  .media-container {
    margin: 0 auto;
    max-width: 1400px;
    grid-column: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;

    div {
      width: 150px;
      filter: grayscale(100%);

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    .media-container {
      flex-flow: row wrap;

      div {
        width: 35%;
        padding: 0.75rem 0.5rem;
      }
    }
  }
`

const Media = () => (
  <MediaRow>
    <div className="media-container">
      <div>
        <Zoom in={true} style={{ transitionDelay: '500ms' }}>
          <PsychologyToday />
        </Zoom>
      </div>
      <div>
        <Zoom in={true} style={{ transitionDelay: '600ms' }}>
          <GirlfriendCircles />
        </Zoom>
      </div>
      <div>
        <Zoom in={true} style={{ transitionDelay: '700ms' }}>
          <MamaPedia />
        </Zoom>
      </div>
      <div>
        <Zoom in={true} style={{ transitionDelay: '800ms' }}>
          <WomansDay />
        </Zoom>
      </div>
      <div>
        <Zoom in={true} style={{ transitionDelay: '900ms' }}>
          <Cosmo />
        </Zoom>
      </div>
    </div>
  </MediaRow>
)

export default Media

