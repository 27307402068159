import React, { useState } from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Connected from '../assets/img/connected.svg'
import Ripple from '../assets/img/ripple.svg'

const AlexWrap = styled.div`
  margin: 2.5rem 0;
  padding: 0 1rem;

  h2 {
    color: ${props => props.theme.colors.grey.light};
    font-family: ${props => props.theme.fontFamily.display};
    text-align: center;
  }
`

const Block = styled.div`
  margin: 1.5rem auto;
  width: 100%;

  h3 {
    text-align: center;
  }

  .lead {
    font-size: 1.25rem;
  }

  &.alex {
    display: flex;
    align-items: center;
    overflow: visible;

    img {
      width: 330px;
      margin-left: -3rem;
      margin-right: 1rem;
    }

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      margin-bottom: 2.5rem;

      img {
        width: 280px;
      }
    }

    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      flex-direction: column;

      img {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  &.numlist {

    .item {
      margin: 1rem 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      @media (min-width: ${props => props.theme.breakpoints.tablet}) {
        align-items: center;
      }

      .num {
        display: flex;
        justify-content: flex-end;
        width: 15%;
        font-size: 5rem;

        span {
          margin-right: -15px;
        }

        @media (min-width: ${props => props.theme.breakpoints.tablet}) {
          height: 13rem;
          font-size: 10rem;
        }
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        div {
          padding: 0 2rem 0 3rem;
          font-size: 1.05rem;
        }

        @media (min-width: ${props => props.theme.breakpoints.tablet}) {
          height: 13rem;

          div {
            padding: 0 5rem 0 4rem;
            font-size: 1.25rem;
          }
        }
      }
      &:nth-child(1) {
        .num {
          // background-color: #81c784;
          color: #dcedc8;
        }
        .content {
          // background-color: #dcedc8;
        }
      }
      &:nth-child(2) {
        .num {
          // background-color: #64b5f6;
          color: #b3e5fc;
        }
        .content {
          // background-color: #b3e5fc;
        }
      }
      &:nth-child(3) {
        .num {
          // background-color: #9c27b0;
          color: #e1bee7;
        }
        .content {
          // background-color: #e1bee7;
        }
      }
      &:nth-child(4) {
        .num {
          // background-color: #673ab7;
          color: #d1c4e9;
        }
        .content {
          // background-color: #d1c4e9;
        }
      }
      &:nth-child(5) {
        .num {
          // background-color: #f44336;
          color: #ffcdd2;
        }
        .content {
          // background-color: #ffcdd2;
        }
      }
      &:nth-child(6) {
        .num {
          // background-color: #ff9800;
          color: #ffe0b2;
        }
        .content {
          // background-color: #ffe0b2;
        }
      }
    }
  }

  img, svg {
    max-width: 100%;
  }

  &.connected {
    width: calc(100% + 2rem);
    margin: 1rem 0 1rem -1rem;
    text-align: center;

    @media (min-width: ${props => props.theme.breakpoints.phone}) {
      width: 100%;
      margin: 3rem auto;
    }

    svg {
      height: auto;
    }
  }

  &.alex-signup {
    height: 120px;
    margin: 3rem auto;
    display: flex;
    justify-content: center;
    align-items: center;

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      margin: 0 auto;

      div {
        margin-right: 0.5rem;
      }

      @media (min-width: ${props => props.theme.breakpoints.phone}) {
        flex-direction: row;
        justify-content: space-around;
        max-width: 700px;
      }

      @media (max-width: ${props => props.theme.breakpoints.phone}) {
        div, input, button {
          width: 100%;
        }
        button {
          margin-top: 1rem;
        }
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.phone}) {
    width: 80%;
  }
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    width: 60%;
  }

  .message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    color: ${props => props.theme.colors.grey.default};
    text-align: center;
    background: ${props => props.theme.colors.white};
    border-radius: 16px;
    box-shadow: 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2);

    a {
      font-weight: 700;
      text-decoration: underline;
    }

  }

  .loading-alex {
    margin: auto;

    .inner {
      stroke: blue;
    }
    .outer {
      stroke: darkblue;
    }
  }
`

const Alex = (
  { title, ...props }
) => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [subscribe, setSubscribe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [subscriber, setSubscriber] = useState(false);
  const [failure, setFailure] = useState(false);

  const alexFreebie = (ev) => {
    setSubscribe(!subscribe)
    setLoading(true)

    let postObj = {
      email_address: email,
      status: "subscribed",
      tags: ["Alex"],
      merge_fields: {
        "FNAME": firstName
      }
    };

    // fetch('http://localhost:9000/mailchimp', {
    fetch('https://selfdevlab-gatsby.netlify.com/.netlify/functions/mailchimp', {
      method: 'POST',
      body: JSON.stringify(postObj),
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(res => res.json())
      .then(response => {
        console.log(response)
        if (response.status === 400 && response.title === "Member Exists") {
          setLoading(false)
          setSubscriber(!subscriber)
          console.log('Success:', JSON.stringify(response))
        } else {
          setLoading(false)
          setSuccess(!success)
        }
      })
      .catch(error => {
        setLoading(false)
        setFailure(!failure)
        console.error('Error:', error)
      });
  }

  return (
    <AlexWrap>
      <h2>{title}</h2>
      <Block>
        <p className="lead">Do you say "yes" to doing things you later wish you'd said "no" to? Wish you had better self-esteem? Do you avoid conflict by trying to be "nice," trying to "make people happy," and "keeping the peace?"</p>
        <p className="lead">If so...</p>
      </Block>
      <Block className="alex">
        <img src="/img/alex.png" alt="Alex" />
        <div>
          <p>Meet <strong>Alex.</strong></p>
          <p>Alex is a smart, hardworking city-girl.</p>
          <p>She has a good job. She’s in good health. She has a good family.</p>
          <p>Alex is grateful for all these things, but she’s also constantly bothered by annoyances that keep cropping up in her life like persistent weeds. For instance, this past week:</p>
        </div>
      </Block>
      <Block className="numlist">
        <div className="item">
          <div className="num">
            <span>1</span>
          </div>
          <div className="content">
            <div>Jared, a coworker who’s normally friendly acted distant towards Alex &mdash; like he was irritated about something. Alex <strong><em>hates</em> confrontation</strong>, so she intentionally avoided Jared as much as possible.
            </div>
          </div>
        </div>
        <div className="item">
          <div className="num">
            <span>2</span>
          </div>
          <div className="content">
            <div>Alex recently decided to pursue a certification to advance her career. She asked her mother, sister, and numerous friends what they thought about it. Several were excited for her, which made her feel great. Alex loves <strong>approval</strong>! But when a few others were lukewarm about the certification, she started <strong>doubting herself</strong>.
            </div>
          </div>
        </div>
        <div className="item">
          <div className="num">
            <span>3</span>
          </div>
          <div className="content">
            <div>She had lots of thoughts like, "You should be doing more creative learning activities with the kids"..."You can’t take time for yourself...unless you’re totally burned out and depressed." (But she still feels guilty taking a nap even then). Alex has had <strong>low self-esteem</strong> ever since she can remember.
            </div>
          </div>
        </div>
        <div className="item">
          <div className="num">
            <span>4</span>
          </div>
          <div className="content">
            <div>Her mom called to talk about the extended family’s decision to do the holiday get-together at a beach resort. Her mom thinks it’s rude to hold a family gathering at a place she personally can’t afford. Alex is her mother’s go-to person to share problems with, and enjoys feeling needed. At the same time, she also tends to feel <strong>responsible to make her mom and other people happy, which gets tiring</strong>.
            </div>
          </div>
        </div>
        <div className="item">
          <div className="num">
            <span>5</span>
          </div>
          <div className="content">
            <div>When Liz asked Alex to watch her kids last-minute again, Alex couldn’t say "no." Even though she was exhausted and it was her day off. Alex envies other people who can say "no" but she just can’t seem to do it herself. She’s not angry; she just <strong>wishes that Liz appreciated it</strong> a little more.
            </div>
          </div>
        </div>
        <div className="item">
          <div className="num">
            <span>6</span>
          </div>
          <div className="content">
            <div>She’s going through a rough patch and <strong>wishes she had more support</strong>. She’s super loyal to her family and friends, but when she has a problem, it seems like they’re not there for her to the degree that she is for them.
            </div>
          </div>
        </div>
      </Block>
      <Block>
        <h3>She's an insightful person, so Alex gets that these annoyances might be problems.</h3>
        <p>She works hard to change the situations and feel better about them, but it always feels like she’s chipping away so slowly. Not getting where she wants to be. And we all know what it’s like to try hard, put in the time, but keep spinning our wheels. We don’t get anywhere because we’re actually trying the wrong things.</p>
        <h3>What Alex doesn’t know yet, is that all six of these problems are actually connected. She also doesn’t know there are 3 specific skills that can help get rid of them.</h3>
      </Block>
      <Block className="connected">
        <Connected />
      </Block>
      <Block>
        <h3>If Alex’s problems sound familiar to you, the Self-Development Lab can help.</h3>
        <p className="lead">
          Just enter your name and email below to <strong _ngcontent-c1="">get 3 new skills — free! —</strong> that will help you get rid of these six annoyances and get you feeling better this week.
        </p>
      </Block>
      <Block className="alex-signup">
        {subscribe && <form>
          <TextField
            id="outlined-name"
            className="input"
            label="First Name"
            type="test"
            name="firstName"
            margin="dense"
            variant="outlined"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
          <TextField
            className="input"
            label="Email"
            type="email"
            name="email"
            autoComplete="email"
            margin="dense"
            variant="outlined"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Button color="primary" variant="contained" onClick={alexFreebie}>
            Get It Now!
          </Button>
        </form> }
        {loading && <Ripple className="loading-alex" width="100" height="100" />}
        {success && <div className="message"><p>Success!</p></div>}
        {subscriber && <div className="message"><p>Congratulations! You already subscribed some time in the past. If you need the download link again, <a href="/">here it is!</a></p></div>}
        {failure && <div className="message"><p>Failed</p></div>}
      </Block>
    </AlexWrap>
  )
}

export default Alex
