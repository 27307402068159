import styled from 'styled-components'

const Content = styled.div`
  grid-column: 2;
  margin-top: -1.5rem;
  padding: 3rem 6rem;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  overflow: hidden;

  img, svg {
    max-width: 100%;
  }
`

export default Content
