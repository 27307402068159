import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Header } from '../components'
import Logo from '../assets/img/logo.svg'

const SubPageHeader = () => (
  <Header>
    <div className="logo">
      <Link to="/">
        <Logo width="250" height="43" />
      </Link>
    </div>
    <div className="nav">
      <Link to="/about">
        About
      </Link>
      <Link to="/blog">
        Blog
      </Link>
    </div>
  </Header>
)

export default SubPageHeader
