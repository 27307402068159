import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Logo from '../assets/img/logo.svg'

const Nav = styled.nav`
  position: fixed;
  z-index: 2;
  width: 100%;
  max-width: 100vw;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};

  .logo {
    padding: 0 3rem;
  }
  .menu {
    padding: 0 3rem;

    a {
      margin: 0 1rem;
      padding: 10px;
      font-size: 0.9rem;
      color: ${props => props.theme.colors.white};
      text-transform: uppercase;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    .logo {
      padding: 0 0 0 1rem;
      width: 60%;

      svg {
        max-width: 100%;
      }
    }
    .menu {
      padding: 0 1rem;
      width: 40%;
      text-align: right;

      a {
        margin: 0 0.25rem;
        font-size: 0.7rem;
      }
    }
  }
`

const Navigation = () => (
  <Nav>
    <div className="logo">
      <Link to="/">
        <Logo width="250" height="43" />
      </Link>
    </div>
    <div className="menu">
      <Link to="/about">
        About
      </Link>
      <Link to="/blog">
        Blog
      </Link>
    </div>
  </Nav>
)

export default Navigation
