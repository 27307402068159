import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  display: flex;
  justify-items: center;
  margin: 4rem 0 0 -2.5rem;
  width: calc(100% + 5rem);
  padding-top: 1rem;
  border-top: 3px dotted whitesmoke;

  a {
    color: ${props => props.theme.colors.primary};
    display: flex;
    align-items: center;
    font-weight: 700;

    &:hover {
      background: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    margin: 3rem 0 0 -1.5rem;
    width: calc(100% + 3rem);
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    margin: 2rem 0 0 -0.5rem;
    width: calc(100% + 1rem);
  }
`

const Prev = styled.div`
  span {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: ${props => props.theme.colors.grey.light};
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    font-size: 0.75rem;

    span {
      font-size: 0.5rem;
    }
  }
`

const Next = styled.div`
  margin-left: auto;
  text-align: right;

  span {
    text-transform: uppercase;
    font-size: 0.8rem;
    color: ${props => props.theme.colors.grey.light};
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    font-size: 0.75rem;

    span {
      font-size: 0.5rem;
    }
  }
`

const PrevNext = ({ next, prev }) => (
  <Wrapper>
    {prev && (
      <Prev>
        <span>Previous</span>
        <Link to={prev.fields.slug}>{prev.frontmatter.title}</Link>
      </Prev>
    )}

    {next && (
      <Next>
        <span>Next</span>
        <Link to={next.fields.slug}>{next.frontmatter.title}</Link>
      </Next>
    )}
  </Wrapper>
)

export default PrevNext

PrevNext.propTypes = {
  next: PropTypes.object,
  prev: PropTypes.object,
}

PrevNext.defaultProps = {
  next: null,
  prev: null,
}
