import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';
import Ripple from '../assets/img/ripple.svg'
import Logo from '../assets/img/logo.svg';

const Content = styled.div`
  grid-column: 2;
  margin-top: -1.5rem;
  padding: 3rem 6rem;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  text-align: center;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  overflow: hidden;

  img, svg {
    max-width: 100%;
  }

  h1 {
    background: url("/img/thhn-logo.png") center center no-repeat;
    background-size: cover;
    height: 300px;
    margin: 0 auto;
    text-indent: -9999px;
    width: 500px;
  }

  .access {
    color: ${props => props.theme.colors.white};
    font-size: 1.15rem;
    line-height: 1.25;
    text-align: center;
    margin-bottom: 0;
  }
`

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
}))(Button);

const Response = styled.div`
  height: 200px;
  max-width: 700px;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.white};

  @media (min-width: ${props => props.theme.breakpoints.phone}) {
    height: 100px;
    margin: 0 auto 1rem auto;
  }

  .signup {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;

    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      flex-direction: column;
      margin-bottom: 2rem;
      padding: 0 2rem;

      div {
        width: 100%;
      }
      button {
        width: 100%;
        margin-top: 0.5rem;
      }
    }

    div {
      min-width: 220px;
      margin-right: 0.5rem;
    }
    button {
      margin-top: 0.25rem;
    }
  }

  .message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    color: ${props => props.theme.colors.grey.default};
    text-align: center;
    background: ${props => props.theme.colors.white};
    border-radius: 16px;
    box-shadow: 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2);

    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
`

const THHNClosed = () => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [subscribe, setSubscribe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [subscriber, setSubscriber] = useState(false);
  const [failure, setFailure] = useState(false);

  const mcFreeEbook = (ev) => {
    setSubscribe(!subscribe)
    setLoading(true)

    let postObj = {
      email_address: email,
      status: "subscribed",
      tags: ["Main"],
      merge_fields: {
        "FNAME": firstName
      }
    };

    fetch('https://selfdevlab.com/.netlify/functions/mailchimp', {
      method: 'POST',
      body: JSON.stringify(postObj),
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(res => res.json())
      .then(response => {
        console.log(response)
        if (response.status === 400 && response.title === "Member Exists") {
          setLoading(false)
          setSubscriber(!subscriber)
          console.log('Success:', JSON.stringify(response))
        } else {
          setLoading(false)
          setSuccess(!success)
        }
      })
      .catch(error => {
        setLoading(false)
        setFailure(!failure)
        console.error('Error:', error)
      });
  }


  return (
    <Content>
      <h1>The Happy, Healthy Nurse</h1>
      <h2>The Happy, Healthy Nurse is closed now</h2>
      <p>But enter your information below and be the first to know when it opens again!</p>
      <Fade in={true} style={{ transitionDelay: '500ms' }}>
        <div>
          <p className="access"> Get your free ebook, <em>How to Say "No" Without Being a Selfish Jerk</em></p>
          <Response>
            {subscribe && <div>
              <form className="signup">
                <TextField
                className="input"
                label="First Name"
                type="test"
                name="firstName"
                margin="dense"
                variant="outlined"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
                <TextField
                  className="input"
                  label="Email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  margin="dense"
                  variant="outlined"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <ColorButton variant="contained" size="large" onClick={mcFreeEbook}>
                  Let Me Know!
                </ColorButton>
              </form>
            </div> }
            {loading && <Ripple width="100" height="100" />}
            {success && <div className="message"><p>Success! I'll keep you posted.</p></div>}
            {subscriber && <div className="message"><p>Congratulations! You already subscribed some time in the past. You're all set.</p></div>}
            {failure && <div className="message"><p>Something didn't work. Please refresh the page and try again.</p></div>}
          </Response>
        </div>
      </Fade>
    </Content>
  )
}

export default THHNClosed;
