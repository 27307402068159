import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Media } from  '../components';

const ContentWrap = styled.main`
  color: rgba(0,0,0,0.5);
  font-size: 1.2rem;
  margin: 0;
  padding: 0;

  .text-center {
    margin: 0 auto;
    text-align: center;
  }

  .narrow {
    margin: 0 auto 2rem;
    max-width: 800px;
  }

  h1, h2, h3, h4, h5, h6 {
    color: rgba(0,0,0,0.5);
  }

  ul {
    list-style: none;
    padding-left: 3rem;

    li {
      margin-bottom: 0.5rem;

      &::before {
        content: "\u2724";
        font-size: 1.4rem;
        margin-left: -2rem;
        padding-right: 1rem;
        vertical-align: baseline;
      }
    }
  }

  blockquote {
    border: 2px solid #efb134;
    border-radius: 20px;
    flex-direction: row-reverse;
    margin: 2rem 0;
    padding: 2rem;
    position: relative;

    div {
      padding-left: 1rem;
      width: 82%;

      &.private {
        width: 100%;
      }

      p {
        font-size: 1.2rem;
        font-style: normal;
        padding-left: 1rem;
      }
    }

    footer {
      height: 100%;
      text-align: center;
      width: 18%;

      &.img {

        &::before {
          background-image: url("/img/fleur.png");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          border: 2px solid #efb134;
          border-radius: 500px;
          content: "";
          display: block;
          height: 90px;
          margin: 0 auto 0.5rem;
          width: 90px;
        }

        &.tim {
          &::before {
            background-image: url("/img/timothy-fountain.jpg");
            background-position: 0 2px;
            background-size: cover;
          }
        }
        &.kate {
          &::before {
            background-image: url("/img/kate-faerber.jpg");
            background-position: 0 -20px;
            background-size: cover;
          }
        }
        &.sunny {
          &::before {
            background-image: url("/img/sunny.png");
          }
        }
      }

      cite {
        font-size: 0.9rem;
        position: relative;

        &::before {
          content: "\u2014";
          margin-right: 0.5rem;
        }
      }
    }

    &:before {
      display: none;
    }
  }

  .numbered-list {
    list-style: none;
  }
`

const Header = styled.div`
  align-items: center;
  background: url("/img/bkg-finally.jpg") center center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  min-height: 700px;
  position: relative;
  text-align: center;

  h2 {
    color: white;
    font-family: ${props => props.theme.fontFamily.sansSerif};
    font-size: 3rem;
    position: relative;
    z-index: 1;

    span {
      display: block;
      font-weight: 400;
      font-size: 2rem;
      margin: 2rem 0;
      text-transform: uppercase;
    }
  }

  &::before {
    background: linear-gradient(to bottom left, rgba(0,0,0,.5), rgba(0,0,0,.75));
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`

const Section = styled.section`
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 500px;
  padding: 5rem 0;

  &.gold {
    background: #efb134;

    p, h2, h3, h4, h5, ul, ol {
      color: white;
    }
  }
  &.indigo {
    background: #0173a1;

    p, h2, h3, h4, h5, ul, ol {
      color: white;
    }
  }
  &.magenta {
    background: #b61f69;

    p, h2, h3, h4, h5, ul, ol {
      color: white;
    }
  }

  &.what-if {
    background: url("/img/tricia-black-dress.png") left bottom no-repeat;
    background-position: 130px 130px;
    background-size: 600px 927px;
    padding-bottom: 2rem;

    h3, h4, h5 {
      font-family: ${props => props.theme.fontFamily.sansSerif};

      &.whatif {
        span {
          text-decoration: underline;
        }
      }
    }

    div {
      font-size: 1.2rem;
      padding-left: 700px;
    }
  }
`

const Narrative = styled.div`
  background: url("/img/bkg-narrative.jpg") center bottom no-repeat;
  background-size: cover;
  padding: 2rem 0;
  position: relative;
  text-align: center;

  h3 {
    color: white;
    font-family: ${props => props.theme.fontFamily.sansSerif} !important;
    font-weight: 400;
    line-height: 1.4;
    max-width: 60%;
    margin: 1rem auto;
    position: relative;
    z-index: 1;
  }

  &::before {
    background: linear-gradient(to bottom right, rgba(0,0,0,.84), rgba(0,0,0,.92));
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`

const Block = styled.div`
  margin: 1.5rem auto;
  width: 100%;

  .lead {
    font-size: 1.25rem;
  }

  &.with-image {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 1440px;
    width: 90%;

    .img {
      margin: 1rem;
      width: auto;

      img {
        border-radius: 30px;
        box-shadow: 0 25px 50px rgba(0,0,0,.2);
      }
    }
    .content {
      padding: 1rem 2rem;
      width: auto;

      &.maria {
        blockquote {
          border-color: white;

          cite {
            color: white;
            display: block;
            padding: 0 1rem;
            text-align: right;

            &::before {
              content: "\u2014";
              margin-right: 1rem;
            }
          }
        }
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
      flex-direction: row;
      .img {
        width: 37%;
      }
      .content {
        width: 63%;
      }
    }
  }

  &.numlist {

    .item {
      margin: 1rem 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      @media (min-width: ${props => props.theme.breakpoints.tablet}) {
        align-items: center;
      }

      .num {
        display: flex;
        justify-content: flex-end;
        width: 15%;
        font-size: 5rem;

        span {
          margin-right: -15px;
        }

        @media (min-width: ${props => props.theme.breakpoints.tablet}) {
          height: 13rem;
          font-size: 10rem;
        }
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;

        div {
          padding: 0 2rem 0 3rem;
          font-size: 1.05rem;
        }

        @media (min-width: ${props => props.theme.breakpoints.tablet}) {
          height: 13rem;

          div {
            padding: 0 5rem 0 4rem;
            font-size: 1.25rem;
          }
        }
      }
      &:nth-child(1) {
        .num {
          color: #dcedc8;
        }
      }
      &:nth-child(2) {
        .num {
          color: #b3e5fc;
        }
      }
      &:nth-child(3) {
        .num {
          color: #e1bee7;
        }
      }
      &:nth-child(4) {
        .num {
          color: #d1c4e9;
        }
      }
      &:nth-child(5) {
        .num {
          color: #ffcdd2;
        }
      }
      &:nth-child(6) {
        .num {
          color: #ffe0b2;
        }
      }
    }
  }

  img, svg {
    max-width: 100%;
  }

  @media (min-width: ${props => props.theme.breakpoints.phone}) {
    width: 80%;

    &.testimonials {
      width: 90%;
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    width: 60%;

    &.testimonials {
      width: 70%;
    }
  }

  &.two-choices {
    padding-top: 1rem;
  }

  &.disclaimer {
    background-color: whitesmoke;
    border-radius: 12px;
    color: gray;
    max-width: 1000px;
    padding: 2rem 3rem 3rem;

    h3 {
      color: gray;
      font-size: 1.2rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  &::before {
    content: "INTRODUCING";
    left: 50%;
    position: absolute;
    top: -1rem;
    transform: translateX(-50%);
  }

  h1 {
    background: url("/img/thhn-logo.png") center center no-repeat;
    background-size: cover;
    height: 300px;
    text-indent: -9999px;
    width: 500px;
  }
`

const Tricia = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;

  .tricia {

    img {
      border-radius: 30px;
      box-shadow: 0 25px 50px rgba(0,0,0,.2);
    }
  }
  .bio {
    font-size: 1.5rem;
    padding: 0 3rem;
    max-width: 900px;

    span {
      text-decoration: underline;
    }
  }
`

const LinkToCourse = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem;

  a {
    background: #efb134; //#69c4a6
    border-radius: 12px;
    color: white;
    padding: 1rem 2.5rem;

    &:hover {
      background: ${darken(0.1, '#efb134')};
    }
  }

  &.last {
    margin: 3rem 0 7rem;
  }
`

const THHNContent = () => {
  const [affiliate, setAffiliate] = useState(false);
  const [afflink, setAfflink] = useState("https://trainings.selfdevlab.com/product/the-happy-healthy-nurse/");

  const checkForAffiliate = () => {
    if (window.location.search !== "") {
      setAfflink("https://trainings.selfdevlab.com/product/the-happy-healthy-nurse/" + window.location.search);
    } else {
      console.log("No query string found");
    }
  }

  useEffect(() => {
    checkForAffiliate();
    console.log(afflink);
  }, []);

  return (
    <ContentWrap>
      <Header className="finally">
        <Block>
          <h2>
            <span>Finally!</span>
            A proven training that helps smart, caring people-pleasers develop guilt-free self-confidence, learn to say "no," and get the time and energy they deserve.
            <span>Made specifically for nurses!</span>
          </h2>
        </Block>
      </Header>

      <Section className="what-if">
        <Block>
          <h4 className="whatif">
            What if you could keep all the great things about being a people-pleaser?
          </h4>
          <ul className="flower-list">
            <li>caring about people</li>
            <li>being kind</li>
            <li>being a hard worker</li>
          </ul>
          <h4><span>But get rid of the negatives &mdash;</span></h4>
          <ul>
            <li>not being able to say "no"</li>
            <li>avoiding conflict</li>
            <li>never feeling good enough</li>
          </ul>
          <h4 className="whatif">
            What if you could get all this just by learning the right communication skills?
          </h4>
          <p>More <strong className="y">self-confidence</strong>. Less guilt. Feeling <strong className="y">calm inside and out</strong>.</p>
          <p>Looking <strong className="y">composed</strong> instead of anxious in confrontations. Being able to say “no” and still being <strong className="y">liked and respected</strong>.</p>
          <p>Having <strong className="y">time and energy</strong> for <em>you</em>.</p>
        </Block>
      </Section>

      <Section className="indigo">
        <Block>
          <h2>You’re a compassionate, hard-working nurse. You love helping people and making a difference in their lives. It’s why you went into nursing!</h2>
        </Block>
      </Section>
      <Narrative>
        <h3>But let’s face it. Sometimes you have to deal with difficult coworkers, managers, and members of your own family.</h3>
      </Narrative>
      <Section className="indigo">
        <Block className="with-image">
          <div className="content">
            <h4>And there comes a time when insightful people-pleasers have an important realization. The realization that keeping the peace, trying to make people happy, and being nice</h4>
            <h2 className="c jdw">just don’t work.</h2>
            <h4>They don’t work in general, and they <strong>especially don’t work with difficult people.</strong></h4>
            <p className="lead">Think about it.</p>
            <p className="lead">Being nicer doesn't make people respect you more. And it definitely doesn't make bossy, narcissistic, controlling people respect you more.</p>
            <p className="lead">Working harder doesn't make people appreciate you more &mdash; especially needy, lazy, unhappy people.</p>
            <p className="lead">Avoiding conflict (keeping the peace) doesn't increase your self-confidence.</p>
          </div>
          <div className="img">
            <img src="/img/there-comes-a-time.jpg" alt="image"/>
          </div>
        </Block>
      </Section>
      <Narrative>
        <h3>So how do you get rid of the negatives of people-pleasing but still remain a caring, kind person?</h3>
      </Narrative>
      <Section className="indigo">
        <Block>
          <h2>Answer:</h2>
          <p className="lead">The secret is developing the right skills.</p>
          <p className="lead">When you’ve got the right skills, your anxiety about how to handle difficult people decreases. You have higher self-esteem, and more time and energy for the things that matter to you.</p>
          <p className="lead">Examples of what having the right skills can do for you:</p>
        </Block>
      </Section>

      <Section>
        <Block>
          <strong>When you don’t have the right skills:</strong>
          <ul className="ml-5">
            <li>You fear confrontation and avoid it like the plague.</li>
          </ul>
          <strong>When you have the right skills:</strong>
          <ul className="ml-5">
            <li>You are able to stay calm in a confrontation. You have the words to say. You’re able to listen, make your point, and make requests. You look composed.</li>
          </ul>
          <strong>When you don’t have the right skills:</strong>
          <ul className="ml-5">
            <li>You feel like you have to say "yes" to things people ask you to do and regret it later. You never have enough time.</li>
          </ul>
          <strong>When you have the right skills:</strong>
          <ul className="ml-5">
            <li>You can say "no" to requests and people that aren’t right for you, while still being a kind person.</li>
          </ul>
          <strong>When you don’t have the right skills:</strong>
          <ul className="ml-5">
            <li>You feel frustrated when you want to be done with a conversation but the other person keeps talking.</li>
          </ul>
          <strong>When you have the right skills:</strong>
          <ul className="ml-5">
            <li>You can end a conversation in a tactful, upbeat manner.</li>
          </ul>
          <strong>When you don’t have the right skills:</strong>
          <ul className="ml-5">
            <li>You are affected by others’ negativity, but don’t know what to do about it.</li>
          </ul>
          <strong>When you have the right skills:</strong>
          <ul className="ml-5">
            <li>You can generate positivity in your personal and professional life instead of waiting for others to set the tone.</li>
          </ul>
          <strong>When you don’t have the right skills:</strong>
          <ul className="ml-5">
            <li>If you have to deal with someone with who’s bossy, condescending, or rude, you get quiet, snarky, or try to make them happier by giving them what they want.</li>
          </ul>
          <strong>When you have the right skills:</strong>
          <ul className="ml-5">
            <li>You can kindly and effectively communicate with difficult people. Even if you feel hurt, annoyed, or anxious.</li>
          </ul>
        </Block>
      </Section>

      <Narrative>
        <h3>But how do you find out what the right skills are? How do you make them work in your own life?</h3>
      </Narrative>

      <Section className="gold">
        <Tricia>
          <div className="tricia">
            <img src="/img/tricia-profile-pic.jpg" alt="Tricia Andor" />
          </div>
          <div className="bio">
            <p>Hi, I’m Tricia Andor. I’m a self-development coach, and I help smart, caring people-pleasers develop guilt-free self-confidence, learn to say “no,” and get the time and energy they deserve. Even if they have to be around difficult people or conflict.</p>
            <p>As a Licensed Professional Counselor, I know which skills actually work. I’ve hand-picked the best and put them into a training and coaching program for you &mdash; <span>The Happy, Healthy Nurse</span>.</p>
          </div>
        </Tricia>
      </Section>

      <Media/>

      <Section>
        <Block>
          <p className="narrow">Somewhere in the middle of helping hundreds of people get out of the people-pleasing and conflict avoidance rut, I had three realizations.</p>
          <Block className="numlist">
            <div className="item">
              <div className="num">
                <span>1</span>
              </div>
              <div className="content">
                <div>
                  Being nice, trying to make people happy, ignoring conflict, and keeping the peace were the main tactics people-pleasers used to deal with conflict. These methods did not work.
                </div>
              </div>
            </div>
            <div className="item">
              <div className="num">
                <span>2</span>
              </div>
              <div className="content">
                <div>
                  Nearly every people-pleaser needs to learn the exact same skills.
                </div>
              </div>
            </div>
            <div className="item">
              <div className="num">
                <span>3</span>
              </div>
              <div className="content">
                <div>
                  Certain skills give people a shortcut, moving them forward 10 steps at a time instead of one step at a time.
                </div>
              </div>
            </div>
          </Block>
        </Block>
      </Section>

      <Section className="magenta">
        <Block className="with-image">
          <div className="img">
            <img src="/img/leaping.jpg"/>
          </div>
          <div className="content maria">
            <p>When my clients used the specific skills I gave them, big things happened. They felt stronger. They handled difficult situations and difficult people more confidently, and were less stressed as a result. I could see it on their faces and hear it in their voices: they were less anxious and more calm.</p>
            <p>Here’s what Maria said after learning the skills:</p>
            <blockquote>
              <div className="private">
                <p>I feel much better about myself. My self-esteem is better. Now I don’t let things go and hope bad situations turn out okay on their own so that people like me. I’ve learned how to assert myself without being contentious, or angry, or argumentative. I’ve learned to not hang onto other people’s negative feelings and make them my problem.</p>
                <cite>Maria, RN</cite>
              </div>
            </blockquote>
            <br/>
            <p>In THHN, I teach you the best of those skills.</p>
          </div>
        </Block>
      </Section>

      <Section className="introducing-thhn">
        <Block>
          <Logo>
            <h1>The Happy, Healthy Nurse</h1>
          </Logo>
          <p className="lead">A proven program that shows you how to stay calm in the middle of conflict, say "no" without guilt, and confidently deal with difficult people.</p>
          <p>Made specifically for smart, caring people-pleasers who hate conflict.</p>
          <p className="lead">The only program designed by a professional counselor with expertise in helping nurses.</p>
        </Block>
      </Section>

      <Section className="indigo">
        <Block>
          <div className="sect what-skills">
            <div className="row">
              <div className="large-12 text-center columns">
                <h3>What will I learn?</h3>
                <p className="lead">In The Happy, Healthy Nurse you’ll learn how to:</p>
                <br/>
              </div>
            </div>
            <div className="row">
              <div className="large-9 large-centered medium-10 medium-centered columns">
                <ul>
                  <li><strong>Say no</strong> without feeling guilty, and without being a jerk.</li>
                  <li><strong>Have more self-confidence</strong> from having the right communication tools to draw upon with difficult people.</li>
                  <li><strong>Have better self-esteem</strong>.</li>
                  <li><strong>Trust the decisions</strong> you make.</li>
                  <li><strong>Communicate effectively</strong> with coworkers and family members &mdash; even when there’s different opinions or hurt feelings.</li>
                  <li><strong>Remain composed</strong> during conflict.</li>
                  <li>Come across as <strong>cool and collected</strong> when dealing with someone with a bad attitude.</li>
                  <li><strong>Stay calm</strong> when annoyed, hurt, or anxious.</li>
                  <li><strong>Manage your feelings</strong> so you <strong>don’t show you’re nervous</strong> during a disagreement.</li>
                  <li>Communicate using <strong>calm, assertive communication</strong>, rather than a passive, aggressive, or passive-aggressive communication communication style.</li>
                  <li>Create self-respecting boundaries.</li>
                  <li>Tell or ask others to do things when necessary, without shrinking back.</li>
                  <li>Gain the <strong>respect of others</strong>.</li>
                  <li><strong>Celebrate</strong> your gifts of <strong>people skills, sensitivity,</strong> and <strong>compassion!</strong> :)</li>
                </ul>
              </div>
            </div>
          </div>
        </Block>
      </Section>

      <Section className="gold">
        <Block>
          <div className="row">
            <div className="large-12 text-center columns">
              <h3>How does it work?</h3>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <p>Each week for 8 weeks, you will receive a video + worksheets -- Discovery Worksheet packets, Progress Tracking Sheets, and Bonus Sheets. Each of the weekly videos and worksheets help you learn one skill at a time.</p>
              <p>During that same 8 week time frame, you get 8 individual coaching sessions with me for 30 minutes each -- one per week throughout training. (This is available if you opt in for that level).</p>
            </div>
          </div>
        </Block>
      </Section>

      <Section>
        <Block>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card">
                <h4 className="text-center mt-5 mb-0">Course schedule:</h4>
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    <ul>
                      <li>Week 1: <strong>Personal Assessment</strong>: How to Respond to Difficult People and Difficult Situations</li>
                      <li>Week 2: <strong>Create a Better Work Experience</strong> by Recognizing What’s in Your Control</li>
                      <li>Week 3: <strong>The Kind Decline</strong>: How to Stop Saying Yes When You Want (or Need) to Say No</li>
                      <li>Week 4: <strong>Let Your Tone Do the Talking</strong>: How to Exit Conversations When You’re Tired, Bored, or Just Ready to Be Done</li>
                      <li>Week 5: <strong>Keeping Yourself Calm</strong>: 3 Techniques to Stay Calm When You’re Anxious, Annoyed, or Hurt</li>
                      <li>Week 6: <strong>The Leather Jacket Technique</strong>: Confidence in Confrontation Using 3 Simple Words</li>
                      <li>Week 7: <strong>The Leather Jacket Technique</strong>: Family and Friends Version</li>
                      <li>Week 8: <strong>Get People to Do What You Want</strong> Without Being Controlling or Manipulative</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Block>
      </Section>

      <Section className="magenta">
        <Block>
          <h2 className="text-center">What will I get with The Happy, Healthy Nurse?<br/><br/></h2>
          <h3>The expertise of a experienced professional</h3>
          <p>You will get to implement proven skills selected by a Licensed Professional Counselor / professional who has the training and experience to know what actually works.</p>
          <p>I’ve helped hundreds of people with different personalities, backgrounds, income levels, and challenges to implement the exact skills you will learn in the Happy, Healthy Nurse!</p>
          <h3>Convenience &mdash; work at your own pace</h3>
          <p>Watch the weekly videos and complete the discovery worksheets and progress tracking sheets at times that fit your schedule. If you can’t participate in a group call, they are recorded and can be listened to at your convenience.</p>
          <p>The training is self-paced and you get to keep the materials forever.</p>
          <h3>Support and community so you don’t have to go it alone.</h3>
          <p>During weekly <strong>one-on-one coaching calls</strong>, you'll have my undivided attention and support to help you learn how to develop each new skill using your unique personality and strengths. The individual calls are an ideal time to get input about anything you feel stuck on, and to ask any specific questions you may have.</p>
        </Block>
      </Section>

      <Section>
        <Block>
          <div className="row">
            <div className="large-12 text-center columns">
              <h2>Just imagine...</h2>
            </div>
          </div>
          <div className="row">
            <div className="large-9 large-centered medium-10 medium-centered columns">
              <p>You clock out from work, say goodbye to people, and get in your car to drive home. “This was a good day,” you think to yourself as you start the car. You notice how much energy you’ve got. You’re not drained. Your body is calm. You feel in control of your life, and are grateful that you get to use your knowledge to help people. You’ve been yourself throughout the day. </p>
              <p>During the ride home, you remember that your kids might ask -- again -- if they can go to the midnight release of their favorite movie sequel on a school night. You breathe evenly and feel content inside. You know you’ll be able to say no without feeling guilty. </p>
              <p>You’ve been practicing your new communication skills in a lot of situations. You take stock of all your progress and are satisfied with the new changes you see in yourself. You take more time for yourself, and are better for it. You’re learning to trust your own decisions rather than always making decisions according to what you think others want you to do.</p>
              <p>You smile, knowing you’ve got great tools in your toolbox that you can use the rest of your life. You’re almost home now. Life is good.</p>
            </div>
          </div>
          <LinkToCourse>
            <a href={afflink} target="_blank">Buy Now!</a>
          </LinkToCourse>
        </Block>
      </Section>

      <Section className="gold">
        <Block>
          <div className="row">
            <div className="large-12 text-center columns">
              <h2>Frequently Asked Questions</h2>
            </div>
          </div>
          <div className="row">
            <div className="large-9 large-centered medium-10 medium-centered columns">
              <article className="faq">
                <div className="question">
                  <p className="lead">Can’t I just do this on my own without the program?</p>
                </div>
                <div className="answer">
                  <ul>
                    <li>If you’re like most people, you’ll run into a number of problems. </li>
                    <ul>
                      <li><strong>Problem One:</strong> Not being able to figure out the right skills to help you. If you do a google search, you’ll get find self-help information that is: theoretical (difficult to apply to real life), bizarre, incorrect, or incomplete. You could read a self-help book. But which to pick amongst the hundreds of thousands? And how to actually apply what you read?</li>
                      <li><strong>Problem Two:</strong> Stalled momentum. Without a system and supportive community, most of us just don’t tend to follow through with even our best intentions.</li>
                      <li><strong>Problem Three:</strong>  It’s hard to have perspective about your own life. In The Happy, Healthy Nurse you get videos, worksheets, and coaching calls to bring you outside perspective.</li>
                    </ul>
                    <li>All of this to say that even if you were able to figure everything out on your own, it's a long, cumbersome process to cobble everything together.</li>
                  </ul>
                </div>
              </article>
              <article className="faq">
                <div className="question">
                  <p className="lead">I’m interested! How much time will it take?</p>
                </div>
                <div className="answer">
                  <ul>
                    <li>It takes about 3 hours per week to complete the coursework: watching the videos, reading and answering worksheet questions, completing weekly challenges, and preparing for the coaching calls.</li>
                  </ul>
                </div>
              </article>
              <article className="faq">
                <div className="question">
                  <p className="lead">What if I have to miss a call? What if I fall behind?</p>
                </div>
                <div className="answer">
                  <ul>
                    <li>No worries! The lessons are released every week, and are designed to be completed on your own schedule that week. There are multiple times available to sign up for individual calls.</li>
                    <li>If you fall behind, no problem, you can work at your own pace. You’ll have access to the materials for life.</li>
                  </ul>
                </div>
              </article>
              <article className="faq">
                <div className="question">
                  <p className="lead">Is there anyone who shouldn’t take this course?</p>
                </div>
                <div className="answer">
                  <p>The training is not a good fit for people who don’t want to change, learn about themselves, or put new skills into practice. The program is definitely not for individuals who aren’t going to do the homework. It’s a great program, but it isn’t magic; it only works if you do the work.</p>
                </div>
              </article>
              <article className="faq">
                <div className="question">
                  <p className="lead">Can you guarantee that the training will work for me?</p>
                </div>
                <div className="answer">
                  <ul>
                    <li>Every person that I’ve seen apply the skills in the Happy, Healthy Nurse has experienced more self-confidence, a greater ability to say “no,” and less guilt. However, if it doesn’t work for you, there’s a 60-day money-back guarantee.</li>
                  </ul>
                  <p>The course is guaranteed 100% for 60 days. That means you get to take entire course (lasts 56 days) and see if it works for you. If you’re not completely happy, just contact me and show me you did the work (by the end of day 60) and I’ll refund 100% of your money.</p>
                  <p>The reason I can offer a guarantee where you take the whole course is I’m confident that you’ll get results!</p>
                  <p>I know what it’s like to consider signing up for something, wondering if it’s really going to work or not. If the course sounds right for you, I’m hoping the guarantee will give you the peace of mind to go ahead sign up.</p>
                </div>
              </article>
            </div>
          </div>
        </Block>
      </Section>

      <Section className="indigo">
        <Block>
          <div className="row">
            <div className="col text-center">
              <h2>Price</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card">
                <h3>$997</h3>
                <p className="card-text">What you get: 8 weeks of videos, released weekly. 8 Discovery Worksheets. 8 Progress Tracking Sheets, 8 Bonus checklists, charts, and cheat sheets. And weekly coaching calls.</p>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item"><strong>Video Training Lessons</strong> where you learn one specific new skill per week for eight weeks.</li>
                  <li className="list-group-item"><strong>Discovery Worksheets</strong> filled with examples, stories, questions, and checklists to help you apply the skills taught in each week’s video.</li>
                  <li className="list-group-item"><strong>Progress Tracking Charts</strong> to help you see the progress you’ve made on each week’s skill-building challenge.</li>
                  <li className="list-group-item"><strong>Bonus Sheets</strong> - Charts, Lists, and Cheat Sheets to help expedite your success.</li>
                  <li className="list-group-item"><strong>Weekly Individual Coaching Calls</strong> - Tailor the whole program to you with personalized help, support, input, perspective, and accountability -- you have my undivided attention.</li>
                </ul>
                <LinkToCourse>
                  <a href={afflink} target="_blank">Buy Now!</a>
                </LinkToCourse>
              </div>
            </div>
          </div>
        </Block>
      </Section>

      <Section>
        <Block className="testimonials">
          <h2 className="text-center">What others are saying</h2>
          <blockquote>
            <div>
              <p><strong>I knew I wasn’t assertive enough.</strong></p>
              <p>I once read this self-help book, because I knew I wasn’t assertive enough. But it was like the author was saying, ‘Be pushy! Push! Push! Push!’ But being aggressive just isn’t me. I don’t like drama. Arguing is not my goal.</p>
              <p>The Happy, Healthy Nurse had a totally different tone than that. The course taught me that I can communicate in a way that I get my needs met, but I don’t have to over-do it. Conversations don’t have to end in a huge argument. The program helped me do what I wanted to do: resolve issues and decrease my anxiety, without picking a fight.</p>
            </div>
            <footer className="img">
              <cite><strong>Maria</strong>, RN</cite>
            </footer>
          </blockquote>
          <blockquote>
            <div>
              <p><strong>Tricia often steers me towards coming to conclusions on my own, instead of taking the oh so common, ‘Here's what I would do...’ approach.</strong></p>
              <p>During our calls, Tricia listened to me, digested my issue, and asked all the right questions, I walked away from our conversations with a sense of confidence that had been missing just a few moments earlier.</p>
            </div>
            <footer className="img sunny">
              <cite><strong>Sunny Shakhawala</strong>, Entrepreneur</cite>
            </footer>
          </blockquote>
          <blockquote>
            <div>
              <p><strong>Before I participated in The Happy, Healthy Nurse I had more anxiety because I wanted to please everybody. But now I don’t have as much anxiety.</strong></p>
              <p>I was at a job for 12 years where I was taken advantage of by coworkers and management. After that, I decided that trying to ‘not create conflict’ and trying to ‘make everyone like me’ wasn’t working. I signed up for The Happy, Healthy Nurse because I didn’t want to be treated like a doormat anymore.</p>
              <p>The training made me feel empowered. Now I can bring up things that I’m upset about. I learned that bringing things up doesn’t have to be contentious. I can do it in a respectful way that feels good.</p>
            </div>
            <footer className="img">
              <cite><strong>Jessica S.</strong>, BSN, RN</cite>
            </footer>
          </blockquote>
          <blockquote>
            <div>
              <p><strong>I’ve learned that if everyone’s not happy, it doesn’t mean I’m failing.</strong></p>
              <p>I really recommend this course! The material isn’t just informational; it’s immediately effective. I was able to quickly absorb and apply it.</p>
              <p>The quality of the content in the amount of time it took to really engage it was really reasonable. People who are really savvy could work it into a lunch break, and still get the value out of it.</p>
            </div>
            <footer className="img tim">
              <cite>
                <strong>Tim Fountain</strong>, Pastor and Author
              </cite>
            </footer>
          </blockquote>
          <blockquote>
            <div>
              <p><strong>I’m a nice person and people were taking advantage of that.</strong></p>
              <p>At first, when a person was mean at work, I thought if I could just do things the right way, everything would be okay. I’d make everybody happy, including myself. I thought if someone treated me badly it was my fault. I must be doing something wrong.</p>
              <p>At some point in the course, I started realizing that it’s not necessarily my behavior that makes people do mean things. I’m not necessarily doing something wrong. Sometimes things just happen a certain way. Sometimes people are the way they are. It really started to click that I’m not going to make everyone happy. And I’m okay with that now.</p>
            </div>
            <footer className="img">
              <cite><strong>Courtney L.</strong>, LPN</cite>
            </footer>
          </blockquote>
          <blockquote>
            <div>
              <p><strong>It’s like processing with a friend who actually has the training to understand and explain life!</strong></p>
              <p>When I talk through a problem with Trish, she approaches it differently from the other people in my life. With her, I leave conversations having clarity as to why some choices feel better or healthier for me.</p>
              <p>She introduces options I hadn’t considered, and often one of those options is the most elegant solution.</p>
              <p>When a different problem arises at a later time, I find that she’s given me the tools to be able distinguish ON MY OWN what choices feel better or healthier. I juggle a lot day-to-day, so being able to quickly make decisions with confidence is a huge asset.</p>
              <p>Best of all, we laugh a lot through the conversation!</p>
            </div>
            <footer className="img kate">
              <cite><strong>Kate Faerber</strong>, Teacher, Mother</cite>
            </footer>
          </blockquote>
        </Block>
      </Section>

      <Narrative>
        <h3>Every now and then, life presents us with an opportunity that can affect us forever.</h3>
      </Narrative>

      <Block className="two-choices">
        <p>And we have two choices. We can ignore that opportunity and then wonder next week, next month, and next year, “Why do I keep having stress…. anxiety….low self-confidence? Why do I keep having the same problems with the same difficult people?”</p>
        <p>Or, we can see the opportunity, <em>take it</em>, and reap the benefits in the weeks and years to come.</p>
        <p>Remember, the program is guaranteed 100% for 60 days. So if it seems right for you, you can have the peace of mind to sign up. There is so much to gain, and nothing to lose.</p>
        <p>Hope to see you on the inside!</p>
        <LinkToCourse className="last">
          <a href={afflink} target="_blank">Buy Now!</a>
        </LinkToCourse>
      </Block>

      <Block className="text-center">
        <img src="/img/fleur.png" alt="flower"/>
      </Block>

      <Block className="disclaimer">
        <h3>Disclaimer</h3>
        <p>The information in this program is for informational purposes only and is neither therapy nor therapeutic advice. This program does not create a therapist-client relationship between you and the content creator. The content creator and coaches are not liable for any losses or damages related to actions or failure to act related to the content in the program. If therapeutic advice or help is needed, consult with a licensed therapist who specializes in the matter for which you are seeking help and who is located in your jurisdiction.</p>
        <h3>Progress Disclaimer</h3>
        <p>Every effort has been made to accurately represent this product and its potential. Even though this industry is one in which one can change their life satisfaction in terms of how they handle boundaries, conflict, people, emotions, thoughts, and behavior, there is no guarantee that you will make any changes using the techniques and ideas in these materials. Examples in these materials are not to be interpreted as a promise or guarantee of life satisfaction. </p>
        <p>Choices about how to approach stress, emotions, thoughts, behavior, self-esteem, and other people are entirely dependent on the person using this product. We do not purport this as an all-your-problems-are-going-to-go-away-overnight scheme. </p>
        <p>Your level of success in attaining the results claimed in our materials depends on the time you devote to the program, ideas, and techniques mentioned, and your knowledge, willingness to try new strategies, and various skills you already possess.</p>
        <p>Since these factors differ according to individuals, we cannot guarantee your success, nor are we responsible for any of your actions.  </p>
        <p>Many factors will be important in determining your actual results, and no guarantees are made that you will achieve results similar to ours or anyone else’s. In fact, no guarantees are made that you will achieve any results from our ideas and techniques in our material. </p>
        <p>Privacy Policy | Terms and Conditions (and Disclosures) | Disclaimer </p>
        <p>Copyright 2019 The Happy, Healthy Nurse is a product of Self Development Lab, LLC.</p>
      </Block>
    </ContentWrap>
)
}

export default THHNContent;
